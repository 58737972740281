import React from 'react';
import {Helmet} from 'react-helmet';
import _ from 'lodash';

import {safePrefix} from '../utils';
import Header from './Header';
import Footer from './Footer';

export default class Body extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title')}</title>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initialScale=1.0" />
                    <meta name="description" content={_.get(this.props, 'pageContext.frontmatter.excerpt') || _.get(this.props, 'pageContext.site.siteMetadata.description')}/>
                    <meta property="og:title" content="DWE Digital | Digital Operations, Growth & Analytics"/>
                    <meta property="og:site_name" content="DWE Digital"/>
                    <meta property="og:url" content="www.dwe.dev"/>
                    <meta property="og:description" content="DWE Digital is a consultancy that helps businesses grow. We focus on scaling digital businesses and focus on Analytics, Operations, Growth & Strategy"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content="https://cdn.sanity.io/images/jphcie2j/production/48ca3707cca297dbbada2c2124b2c334c263d015-640x113.png" ></meta>
                    <link href="https://fonts.googleapis.com/css?family=Karla:400,400i,700,700i&display=swap" rel="stylesheet" /> 
                    <link rel="stylesheet" href={safePrefix('assets/css/main.css')}/>
                </Helmet>
                <div id="page" className={'site palette-' + _.get(this.props, 'pageContext.site.siteMetadata.color_scheme') + ' accent-' + _.get(this.props, 'pageContext.site.siteMetadata.accent_color')}>
                  <Header {...this.props} />
                  <main id="content" className="site-content">
                    {this.props.children}
                  </main>
                  <Footer {...this.props} />
                </div>
            </React.Fragment>
        );
    }
}
