import React from 'react';
import _ from 'lodash';

import {htmlToReact} from '../utils';
import ActionLink from './ActionLink';

export default class Footer extends React.Component {
    render() {
        return (
          <footer id="colophon" className="site-footer outer">
            <div className="inner">
              <div className="site-footer-inside">
                <div className="site-info">
                  {_.get(
                    this.props,
                    'pageContext.site.siteMetadata.footer.content'
                  ) && (
                    <span className="copyright">
                      &copy; DWE Digital Ltd {new Date().getFullYear()}
                    </span>
                  )}
                  {_.map(
                    _.get(
                      this.props,
                      'pageContext.site.siteMetadata.footer.links'
                    ),
                    (action, action_idx) => (
                      <ActionLink
                        key={action_idx}
                        {...this.props}
                        action={action}
                      />
                    )
                  )}
                </div>
                {_.get(
                  this.props,
                  'pageContext.site.siteMetadata.footer.has_social'
                ) && (
                  <div className="social-links">
                    {_.map(
                      _.get(
                        this.props,
                        'pageContext.site.siteMetadata.footer.social_links'
                      ),
                      (action, action_idx) => (
                        <ActionLink
                          key={action_idx}
                          {...this.props}
                          action={action}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </footer>
        );
    }
}
